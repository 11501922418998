import React from 'react';
import styled from "styled-components";
import MiniLogoSvg from "../styles/images/MiniLogoSvg";
import PhoneSvg from "../styles/images/PhoneSvg";
import CloseSvg from "../styles/images/CloseSvg";
import {NavItem} from "./styled/NavItem";
import MobileFooter from "./styled/MobileFooter";

const Logo = styled(MiniLogoSvg)`
  margin-right: auto;

  path {
    fill: #2C3532;
  }
`;

const Header = styled.div`
  background: #e8f0ef;
  display: flex;
  align-items: center;
  padding: 60px 25px 0 25px;
`;

const Phone = styled.div`
  display: flex;
  align-items: center;
  color: #2c3532;
  font-family: Arial, sans-serif;
  font-size: 18px;

  svg {
    flex: 0 0 auto;
    margin-right: 5px;

    path {
      stroke-width: 1.5;
      stroke: #2C3532;
    }
  }
`;

const Menu = styled.div`
  background: #e8f0ef;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 80px 33px;
  text-align: center;

  ${NavItem} {
    font-size: 24px;
    line-height: 28px;
    color: #2c3532;
    margin-bottom: 8px;
  }
`;


const Close = styled(CloseSvg)`
  margin-left: 26px;
`;

const StyledMobileMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
`;

type MobileMenuProps = {
    onClose: () => void
}

function MobileMenu({onClose}: MobileMenuProps) {
    return (
        <StyledMobileMenu>
            <Header>
                <Logo/>
                <Phone><PhoneSvg/>+972 58438 83 73</Phone>
                <Close onClick={onClose}/>
            </Header>
            <Menu>
                <NavItem onClick={onClose} href="#whycerberus">Почему мы</NavItem>
                <NavItem onClick={onClose} href="#use">Применение</NavItem>
                <NavItem onClick={onClose} href="#howwork">Принцип работы</NavItem>
                <NavItem onClick={onClose} href="#contacts">Контакты</NavItem>
            </Menu>
            <MobileFooter/>
        </StyledMobileMenu>
    );
}

export default MobileMenu;