import React from 'react';

function CloseSvg(props: any) {
    return (
        <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 2L2 28" stroke="#2C3532" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M2 2L28 28" stroke="#2C3532" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
}

export default CloseSvg;