import styled from "styled-components";

export const NavItem = styled.a`
  display: flex;
  font-size: 18px;
  color: white;
  text-decoration: none;

  cursor: pointer;
  position: relative;
  padding: 10px 0;

  &:hover {
    &:after {
      position: absolute;
      content: '';
      border-bottom: 2px solid #04898C;
      width: 100%;
      bottom: 0;
    }
  }
`;