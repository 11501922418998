import React from 'react';
import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #057477;
  border-radius: 50px;
  color: white;
  padding: 14px 20px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(20, 173, 176, 0.5);
  cursor: pointer;
  line-height: 25px;
  
  &:hover {
    background: #068589;
  }

  &:active {
    background: #045356;
  }
`;

function Button({children, className = ""}: any) {
    return (
        <StyledButton className={className}>
            {children}
        </StyledButton>
    );
}

export default Button;