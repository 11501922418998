import React from 'react';

function MiniLogoSvg(props: any) {
    return (
        <svg {...props} version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="80px" height="54.3px" viewBox="0 0 80 54.3">
            <path fill="#FFFFFF" d="M1.4,52.8c-0.9-1-1.4-2.3-1.4-3.7c0-1.4,0.5-2.7,1.4-3.7c0.9-1,2.1-1.5,3.3-1.5h0c1.3,0,2.4,0.5,3.3,1.5
	c0,0,0,0,0,0c0,0,0,0,0,0l-0.3,0.3l-0.8,0.9c0,0,0,0,0,0c-1.2-1.3-3.2-1.3-4.4,0c-0.6,0.7-0.9,1.6-0.9,2.4c0,0.9,0.3,1.8,0.9,2.4
	c1.2,1.3,3.2,1.3,4.4,0c0,0,0,0,0,0L8,52.8c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,1-2.1,1.5-3.3,1.5S2.3,53.8,1.4,52.8z"/>
            <path fill="#FFFFFF" d="M10.7,54.3C10.7,54.3,10.7,54.3,10.7,54.3l0-10.3c0,0,0,0,0,0h6.2c0,0,0,0,0,0v1.7c0,0,0,0,0,0h-4.8v2.6h3.4
	c0,0,0,0,0,0V49c0,0,0,0,0,0v0.3c0,0,0,0,0,0V50c0,0,0,0,0,0h-3.4v2.6h4.8c0,0,0,0,0,0v1.7c0,0,0,0,0,0L10.7,54.3L10.7,54.3z"/>
            <path fill="#FFFFFF" d="M22.8,54.3h-1.4c0,0,0,0,0,0V44c0,0,0,0,0,0h3.9c1.7,0,3.2,1.7,3.2,3.7c0,1.4-0.6,2.6-1.6,3.2l1.3,1.5
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0v1.7c0,0,0,0,0,0c-0.5,0-1-0.2-1.4-0.7c0,0,0,0,0,0l-1.7-2c-0.1-0.1-0.2-0.2-0.3-0.2h-2.2
	L22.8,54.3C22.8,54.3,22.8,54.3,22.8,54.3z M22.8,49.7h2.5c0.9,0,1.7-0.9,1.7-2c0-1.1-0.8-2-1.7-2h-2.5V49.7z"/>
            <path fill="#FFFFFF" d="M36.6,54.3H32c0,0,0,0,0,0V44c0,0,0,0,0,0h4.5c1.4,0,2.6,1.4,2.6,3c0,0.8-0.3,1.6-0.8,2.2
	c0.5,0.6,0.8,1.3,0.8,2.2C39.1,53,38,54.3,36.6,54.3z M33.5,48.3h3.1c0.6,0,1.1-0.6,1.1-1.3c0-0.7-0.5-1.3-1.1-1.3h-3.1V48.3z
	 M33.5,52.6h3.1c0.6,0,1.1-0.6,1.1-1.3c0-0.7-0.5-1.3-1.1-1.3h-3.1V52.6z"/>
            <path fill="#FFFFFF" d="M42.7,54.3C42.7,54.3,42.7,54.3,42.7,54.3l0-10.3c0,0,0,0,0,0h6.2c0,0,0,0,0,0v1.7c0,0,0,0,0,0h-4.8v2.6h3.4
	c0,0,0,0,0,0V49c0,0,0,0,0,0v0.3c0,0,0,0,0,0V50c0,0,0,0,0,0h-3.4v2.6h4.8c0,0,0,0,0,0v1.7c0,0,0,0,0,0L42.7,54.3L42.7,54.3z"/>
            <path fill="#FFFFFF" d="M53.9,54.3h-1.4c0,0,0,0,0,0V44c0,0,0,0,0,0h3.9c1.7,0,3.2,1.7,3.2,3.7c0,1.4-0.6,2.6-1.6,3.2l1.3,1.5
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0v1.7c0,0,0,0,0,0c-0.5,0-1-0.2-1.4-0.7c0,0,0,0,0,0l-1.7-2c-0.1-0.1-0.2-0.2-0.3-0.2h-2.2
	L53.9,54.3C53.9,54.3,53.9,54.3,53.9,54.3z M53.9,49.7h2.5c0.9,0,1.7-0.9,1.7-2c0-1.1-0.8-2-1.7-2h-2.5V49.7z"/>
            <path fill="#FFFFFF" d="M63.1,50.2V44c0,0,0,0,0,0h1.4c0,0,0,0,0,0v6.1c0,1.4,0.9,2.5,2.1,2.5s2.1-1.1,2.1-2.5V44c0,0,0,0,0,0h1.4
	c0,0,0,0,0,0v6.1c0,2.3-1.6,4.2-3.6,4.2C64.7,54.3,63.1,52.5,63.1,50.2z"/>
            <path fill="#FFFFFF" d="M73.8,52.8C73.8,52.8,73.8,52.8,73.8,52.8l0.8-0.9l0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.6,0.7,1.4,1,2.2,1h0.4
	c0.6,0,1.1-0.5,1.1-1.2c0-0.6-0.3-1-0.8-1.2l-1.9-0.5c-1.2-0.3-2-1.5-2-2.8c0-1.6,1.2-2.9,2.6-2.9h0.4c0,0,0,0,0,0
	c1.2,0,2.4,0.5,3.2,1.5c0,0,0,0,0,0c0,0,0,0,0,0l-0.9,1L79,46.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-0.7-1.4-1-2.2-1h-0.4
	c-0.6,0-1.1,0.5-1.1,1.2c0,0.6,0.3,1,0.8,1.2l1.9,0.5c1.2,0.3,2,1.5,2,2.8c0,1.6-1.2,2.9-2.6,2.9H77c0,0,0,0,0,0
	C75.8,54.3,74.6,53.8,73.8,52.8C73.8,52.8,73.8,52.8,73.8,52.8z"/>
            <path fill="#FFFFFF" d="M63.1,7.5l-3.5-2.7c-0.2-0.1-0.4-0.2-0.7-0.2h-4.8C54,4.3,53.9,4.2,53.7,4l-4.8-3.7C48.6,0.1,48.3,0,48,0
	h-9.1h-5.9c-0.3,0-0.6,0.1-0.9,0.3L27.2,4c-0.2,0.1-0.3,0.3-0.5,0.6H22c-0.2,0-0.5,0.1-0.7,0.2l-3.5,2.7c-0.3,0.2-0.5,0.6-0.5,1v0.9
	h0c0,3.5,0,7,0,10.5c0,1.5,0.5,2.8,1.3,4.1c1,1.5,2.3,2.8,3.7,3.8c1.5,1.1,3,2.1,4.7,3c0.3,0.1,0.6,0.1,0.8,0c0.9-0.5,1.7-1,2.6-1.5
	c0.1,0.1,0.3,0.3,0.4,0.4c2.7,2.6,5.7,4.6,9,6.3c0.4,0.2,0.8,0.2,1.2,0c2.2-1.2,4.4-2.5,6.4-4.1c1.1-0.8,2.1-1.7,3-2.7
	c0.8,0.5,1.7,1,2.6,1.5c0.3,0.1,0.6,0.1,0.8,0c1.6-0.8,3.2-1.8,4.7-3c1.4-1.1,2.7-2.3,3.7-3.8c0.8-1.2,1.3-2.5,1.3-4.1
	c0-3.5,0-7,0-10.5h0V8.5C63.6,8.1,63.4,7.7,63.1,7.5z M61,23.3c-0.9,1.3-2,2.4-3.3,3.3c-1.3,1-2.7,1.9-4.1,2.6c-0.1,0-0.2,0-0.3,0
	c-2-1.1-3.9-2.3-5.6-3.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.5-0.7-2.2-0.3l0,0c0,0,0,0,0,0c0.4,0.5,0.8,1,1.3,1.4
	c0.7,0.7,1.5,1.4,2.3,2c-0.8,0.8-1.6,1.5-2.5,2.2c-1.8,1.4-3.7,2.6-5.6,3.6c-0.1,0.1-0.3,0.1-0.5,0c-2.7-1.5-5.4-3.2-7.7-5.4
	c-0.1-0.1-0.3-0.2-0.4-0.4c0.8-0.6,1.6-1.2,2.3-2c0.5-0.4,0.9-0.9,1.3-1.4c0,0,0,0,0,0l0,0c-0.7-0.4-1.6-0.3-2.2,0.3
	c-0.1,0.1-0.2,0.2-0.3,0.3c-1.7,1.6-3.6,2.8-5.6,3.9c-0.1,0-0.2,0-0.3,0c-1.4-0.8-2.8-1.6-4.1-2.6c-1.2-0.9-2.4-2-3.3-3.3
	c-0.8-1.1-1.2-2.4-1.2-3.8l0-10.1h0V9.1c0-0.1,0.1-0.3,0.2-0.4l3.3-2.6c0.1-0.1,0.2-0.1,0.2-0.1h6.1h3.8c0.1,0,0.2,0,0.2,0.1
	c0.5,0.4,1.3,0.4,1.8,0c0.2-0.1,0.3-0.2,0.5-0.4l-1.3-1c-0.2-0.1-0.4-0.2-0.7-0.2h-2.6l3-2.3c0.1-0.1,0.2-0.1,0.3-0.1h5.2h8.4
	c0.1,0,0.2,0,0.3,0.1l3,2.3H48c-0.2,0-0.5,0.1-0.7,0.2l-1.3,1c0.2,0.1,0.3,0.2,0.5,0.4c0.5,0.4,1.2,0.4,1.8,0
	c0.1-0.1,0.2-0.1,0.2-0.1h3.8h6.1c0.1,0,0.2,0,0.2,0.1L62,8.8c0.1,0.1,0.2,0.2,0.2,0.4v0.3h0l0,10.1C62.2,20.9,61.7,22.2,61,23.3z"
            />
            <path fill="#FFFFFF" d="M47.2,10.8c-0.3-2.2-1.9-3.3-2-3.4L44.7,7l-1.9,2.7c-0.8-0.3-1.6-0.5-2.4-0.4c-0.8,0-1.5,0.2-2.2,0.4
	l-1.9-2.7l-0.6,0.4c-0.1,0.1-1.6,1.2-2,3.4c-0.1,1,0,1.9,0.3,2.9c-0.5,1.1-0.8,2.3-0.8,3.6v3.4c0,0.3,0.1,0.6,0.4,0.7l1.7,1.1
	c0.7,0.4,1.2,1,1.7,1.7c0.1,0.2,0.3,0.4,0.4,0.7c0.5,1.1,1.7,1,1.7,1h1.2h1.2c0,0,1.2,0.1,1.7-1c0.2-0.2,0.3-0.5,0.4-0.7
	c0.5-0.7,1-1.3,1.7-1.7l1.7-1.1c0.2-0.2,0.4-0.4,0.4-0.7v-3.5h0c0-1.2-0.2-2.4-0.7-3.4C47.2,12.7,47.3,11.8,47.2,10.8z M44.9,9.3
	c0.3,0.4,0.7,1,0.8,1.8c0,0.3,0,0.5,0,0.8c-0.5-0.6-1-1.1-1.6-1.5L44.9,9.3z M35.2,11c0.1-0.8,0.5-1.4,0.8-1.8l0.8,1.2
	c-0.6,0.4-1.2,0.9-1.7,1.5C35.1,11.6,35.1,11.3,35.2,11z M45.8,20.1L44,21.2c-0.5,0.3-0.8,0.7-1.1,1.2c-0.4,0.7-1.2,1.6-2.5,1.6
	c-1.2,0-2.1-1-2.5-1.7c-0.3-0.5-0.7-0.9-1.1-1.2l-1.7-1.1c0,0-0.2-0.1-0.2-0.5l0.1-2.5c0-3.3,2.4-6,5.4-6c3,0,5.4,2.7,5.4,6h0
	l0.1,2.5C45.9,20,45.8,20.1,45.8,20.1z"/>
            <path fill="#FFFFFF"
                  d="M40.4,19.8c-0.5,0-0.9,1-0.9,1.6s0.4,1,0.9,1c0.5,0,0.9-0.4,0.9-1C41.3,20.8,40.9,19.8,40.4,19.8z"/>
            <path fill="#FFFFFF" d="M30.3,16.9c0.2-0.4,0.2-0.9,0.2-1.3c-0.2-1-0.9-1.5-1-1.5l-0.3-0.2L28.3,15c-0.4-0.1-0.8-0.2-1.2-0.2
	c-0.4,0-0.7,0.1-1.1,0.2l-1-1.2L24.7,14c0,0-0.8,0.6-1,1.5c-0.1,0.4,0,0.9,0.2,1.3c-0.2,0.5-0.4,1-0.4,1.6V20c0,0.1,0.1,0.3,0.2,0.3
	l0.9,0.5c0.3,0.2,0.6,0.5,0.9,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.5,0.8,0.5,0.8,0.5h0.6h0.6c0,0,0.6,0.1,0.8-0.5
	c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.5-0.6,0.9-0.8l0.9-0.5c0.1-0.1,0.2-0.2,0.2-0.3v-1.6h0C30.7,17.9,30.5,17.3,30.3,16.9z
	 M29.3,14.9c0.2,0.2,0.4,0.5,0.4,0.8c0,0.1,0,0.2,0,0.4c-0.2-0.3-0.5-0.5-0.8-0.7L29.3,14.9z M24.5,15.6c0.1-0.4,0.3-0.7,0.4-0.8
	l0.4,0.5c-0.3,0.2-0.6,0.4-0.8,0.7C24.5,15.9,24.5,15.8,24.5,15.6z M29.8,19.8l-0.9,0.5c-0.2,0.1-0.4,0.3-0.6,0.5
	c-0.2,0.3-0.6,0.7-1.2,0.7c-0.6,0-1-0.4-1.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5l-0.9-0.5c0,0-0.1,0-0.1-0.2l0.1-1.1
	c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7h0l0.1,1.1C29.9,19.7,29.8,19.8,29.8,19.8z"/>
            <path fill="#FFFFFF"
                  d="M26.7,19.8c-0.2,0-0.4,0.3-0.4,0.5s0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3C27.1,20.1,26.9,19.8,26.7,19.8z"
            />
            <path fill="#FFFFFF" d="M57,16.9c0.2-0.4,0.2-0.9,0.2-1.3c-0.2-1-0.9-1.5-1-1.5l-0.3-0.2L54.9,15c-0.4-0.1-0.8-0.2-1.2-0.2
	c-0.4,0-0.7,0.1-1.1,0.2l-1-1.2L51.4,14c0,0-0.8,0.6-1,1.5c-0.1,0.4,0,0.9,0.2,1.3c-0.2,0.5-0.4,1-0.4,1.6V20c0,0.1,0.1,0.3,0.2,0.3
	l0.9,0.5c0.3,0.2,0.6,0.5,0.9,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.5,0.8,0.5,0.8,0.5h0.6h0.6c0,0,0.6,0.1,0.8-0.5
	c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.5-0.6,0.9-0.8l0.9-0.5c0.1-0.1,0.2-0.2,0.2-0.3v-1.6h0C57.3,17.9,57.2,17.3,57,16.9z M56,14.9
	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.1,0,0.2,0,0.4c-0.2-0.3-0.5-0.5-0.8-0.7L56,14.9z M51.1,15.6c0.1-0.4,0.3-0.7,0.4-0.8l0.4,0.5
	c-0.3,0.2-0.6,0.4-0.8,0.7C51.1,15.9,51.1,15.8,51.1,15.6z M56.4,19.8l-0.9,0.5c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.3-0.6,0.7-1.2,0.7
	c-0.6,0-1-0.4-1.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5l-0.9-0.5c0,0-0.1,0-0.1-0.2l0.1-1.1c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7h0
	l0.1,1.1C56.5,19.7,56.4,19.8,56.4,19.8z"/>
            <path fill="#FFFFFF"
                  d="M53.3,19.8c-0.2,0-0.4,0.3-0.4,0.5s0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3C53.8,20.1,53.6,19.8,53.3,19.8z"
            />
        </svg>

    );
}

export default MiniLogoSvg;