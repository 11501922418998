import React from 'react';
import MiniLogoSvg from "../../styles/images/MiniLogoSvg";
import Button, {StyledButton} from "../Button";
import PhoneSvg from "../../styles/images/PhoneSvg";
import BurgerSvg from "../../styles/images/BurgerSvg";
import styled from "styled-components";

export const StyledMobileFooter = styled.div`
  background: #2c3532;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 25px 33px 25px;

  ${StyledButton} {
    padding-left: 28px;
    padding-right: 28px;
    box-shadow: 0 4px 4px rgba(16, 100, 102, 0.25);

    svg {
      path {
        stroke-width: 1.5;
      }
    }
  }
`;

const LogoBlock = styled.div`

`;

const Copyright = styled.div`
  margin-top: 10px;
  color: #959a98;
  font-family: Arial, sans-serif;
  font-size: 10px;
`;

const Right = styled.div`
  padding-left: 48px;
  border-left: 1px solid #d8b08c;
  display: flex;
  height: 100%;
  align-items: center;
`;

function MobileFooter({onBurger}: any) {
    return (
        <StyledMobileFooter>
            <LogoBlock>
                <MiniLogoSvg/>
                <Copyright>© Все права защищены</Copyright>
            </LogoBlock>
            <Button>
                <PhoneSvg/>
            </Button>
            <Right>
                <BurgerSvg onClick={onBurger}/>
            </Right>
        </StyledMobileFooter>
    );
}

export default MobileFooter;