import React from 'react';
import styled from "styled-components";
import icon1 from "../styles/images/icon1.png";
import icon2 from "../styles/images/icon2.png";
import icon3 from "../styles/images/icon3.png";
import icon4 from "../styles/images/icon4.png";
import icon5 from "../styles/images/icon5.png";
import icon6 from "../styles/images/icon6.png";

const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
`;

const Stat = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Number = styled.div`
  font-family: Audiowide, sans-serif;
  color: #D8B08C7F;
  font-size: 150px;
  line-height: 105px;
`;

const Text = styled.div`
  position: absolute;
  bottom: -5px;
  color: #057477;
  font-family: Bicubik, sans-serif;
  font-size: 20px;
  white-space: nowrap;
  text-align: end;
  transform: translateX(32%);
`;
const SecondText = styled(Text)`
  transform: translateX(18%);
`;

const Line = styled.div`
  width: 100%;
  margin-top: 90px;
  border-bottom: 2px solid #057477;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 10%;
  color: #2c3532;
  font-size: 16px;
  font-weight: bold;
`;

const StyledFourthBlock = styled.div`
  background: #e8f0ef;
  padding: 100px 145px;
  width: 100%;

  h1 {
    font-size: 24px;
    color: #057477;
    font-weight: normal;
  }


  @media (max-width: 1100px) {
    padding: 100px 25px;
    ${Stats} {
      flex-direction: column;

      ${Stat} {
        &:not(:last-child) {
          margin-bottom: 50px;
        }

        ${Number} {
          font-size: 100px;
          line-height: 80px;
        }

        ${Text} {
          font-size: 18px;
          transform: none;
          padding-left: 70px;
          white-space: normal;
        }

        ${SecondText} {
          padding-left: 70px;
        }
      }
    }

    ${Icons} {
      flex-wrap: wrap;
      justify-content: center;

      ${Icon} {
        display: flex;
        align-items: center;
        text-align: center;
        width: 33%;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 360px) {
    ${Icons} {
      ${Icon} {
        font-size: 10px;
      }
    }
  }
`;

function FourthBlock() {
    return (
        <StyledFourthBlock>
            <h1>Ваша переписка принадлежит только вам</h1>
            <Icons>
                <Icon>
                    <img src={icon1} alt="icon1"/>
                    <p>Размещение на вашем сервере</p>
                </Icon>
                <Icon>
                    <img src={icon2} alt="icon2"/>
                    <p>Многофакторная защита</p>
                </Icon>
                <Icon>
                    <img src={icon3} alt="icon3"/>
                    <p>Сквозное шифрование</p>
                </Icon>
                <Icon>
                    <img src={icon4} alt="icon4"/>
                    <p>Настоящая анонимность</p>
                </Icon>
                <Icon>
                    <img src={icon5} alt="icon5"/>
                    <p>Централизованное
                        управление</p>
                </Icon>
                <Icon>
                    <img src={icon6} alt="icon6"/>
                    <p>Данные не хранятся</p>
                </Icon>
            </Icons>
            <Line/>
            <Stats>
                <Stat>
                    <Number>28</Number>
                    <Text>серверов по всему миру</Text>
                </Stat>
                <Stat>
                    <Number>346</Number>
                    <SecondText>клиентов под нашей защитой</SecondText>
                </Stat>
            </Stats>
        </StyledFourthBlock>
    );
}

export default FourthBlock;