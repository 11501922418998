import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Button, {StyledButton} from "../components/Button";
import fb from "../styles/images/fb.png"
import msg from "../styles/images/msg.png"
import inst from "../styles/images/inst.png"
import yt from "../styles/images/yt.png"
import Slider from "../components/Slider";


const Bottom = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: 55px;
  }
`;

const Socials = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;

    &:hover {
      opacity: .5;
    }

    &:active {
      opacity: .9;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;

  address {
    font-style: normal;
    font-size: 16px;
    color: #2c3532;
    margin-bottom: 22px;
  }

  h2 {
    margin-bottom: 33px;
  }
`;

const Phone = styled.div`
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #2C3532;
  line-height: 24px;
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 35px;

    ${StyledButton} {
      margin-top: 50px;
      padding-left: 85px;
      padding-right: 85px;
    }

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #000;
      font-size: 16px;
      outline: none;
      padding-bottom: 5px;

      &:first-child {
        margin-bottom: 45px;
      }

      &::placeholder {
        color: #2C35327F;
      }
    }
  }
`;

const StyledFifthBlock = styled.div`
  padding: 100px 145px;
  width: 100%;

  > h1 {
    margin-bottom: 50px;
  }

  h1 {
    color: #057477;
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
    color: #2c3532;
    line-height: 22px;
  }

  @media (max-width: 1100px) {
    padding: 100px 25px;

    ${Bottom} {
      flex-direction: column-reverse;

      ${Questions} {
        margin-bottom: 70px;
      }

      ${Contact} {
        ${Phone} {
          margin-bottom: 30px;
        }
      }
    }
  }
`;

function FifthBlock() {
    const [count, setCount] = useState<number>(3);

    useEffect(() => {
        setCount(window.innerWidth > 1100 ? 3 : 1);
        const onresize = () => {
            setCount(window.innerWidth > 1100 ? 3 : 1)
        }

        window.addEventListener("resize", onresize)

        return () => {
            window.removeEventListener("resize", onresize);
        }
    }, [])

    return (
        <StyledFifthBlock>
            <h1 id={"howwork"}>Как поможет Сerberus в сложных ситуациях</h1>
            <h2>рассмотрим несколько ситуаций</h2>
            <Slider count={count} slides={[
                {
                    title: "Телефон пользователя или занят, потерян или попал в руки злоумышленников",
                    text1: "История переписки хранится в телефоне и может быть расшифрована с помощью специального ПО при попадании телефона в чужие руки",
                    text2: "Информация не хранится на телефоне и не попадет в чужие руки при потере телефона"
                },
                {
                    title: "Пользователя вынуждают назвать пароль и разблокировать телефон и мессенджер",
                    text1: "Информация попадет в чужие руки при разблокировании телефона и мессенджера",
                    text2: "Опция фейковый аккаунт и пароль под принуждением защитят пользователя"
                },
                {
                    title: "Переписка пользователя и его контакты запрошены государственными органами",
                    text1: "Провайдеры публичных мессенджеров подчиняются законам стран, где они действуют и обычно выдают данные по запросу властей без уведомления об этом пользователей",
                    text2: "Поставщики программного обеспечения не могут иметь доступа к информации своих клиентов и не обязаны ее предоставлять по закону"
                },
                {
                    title: "Сотрудник увольняется или скомпрометирован",
                    text1: "Информация может бесконтрольно сохраняться на телефоне пользователя, важную информацию невозможно «отозвать» в указанных случаях",
                    text2: "Информация не хранится на телефоне, пользователь может быть одним кликом отключен от доступа к Cerberus"
                },
                {
                    title: "Злоумышленники пишут вам или вашим сотрудниками, чтобы получить доступ к информации",
                    text1: "Пользователи не защищены от атак социальной инженерии, большинство публичных мессенджеров не анонимны, привязаны к номеру телефона",
                    text2: "Пользователи полностью защищены, поскольку требуется дополнительная авторизация пользователей для начала общения"
                }
            ]}/>
            <Bottom>
                <Contact>
                    <h1 id={"contacts"}>Свяжитесь с нами</h1>
                    <h2>Контакты</h2>
                    {/*<address>Моше Авив 6 Ор — Иегуда, Израиль 60 371</address>*/}
                    <Phone>+972 584388373</Phone>
                    <Socials>
                        <img src={yt} alt=""/>
                        <img src={inst} alt=""/>
                        <img src={fb} alt=""/>
                        <img src={msg} alt=""/>
                    </Socials>
                </Contact>
                <Questions>
                    <h1>Остались вопросы?</h1>
                    <h2>наш менеджер свяжется с вами в течение 30 минут</h2>
                    <form action="">
                        <input type="text" placeholder={"Имя"}/>
                        <input type="text" placeholder={"Номер телефона"}/>
                        <Button>Отправить</Button>
                    </form>
                </Questions>
            </Bottom>
        </StyledFifthBlock>
    );
}

export default FifthBlock;