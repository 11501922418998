import React from 'react';

function LeftArrowSvg(props:any) {
    return (
        <svg {...props} width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.26346 14.7561L14.0811 0.514971C14.6991 -0.171657 15.7015 -0.171657 16.3195 0.514971L17.8146 2.17621C18.4319 2.86211 18.4326 3.97302 17.8173 4.66038L7.65877 15.9996L17.8166 27.3396C18.4326 28.027 18.4313 29.1379 17.814 29.8238L16.3188 31.485C15.7009 32.1717 14.6984 32.1717 14.0805 31.485L1.26346 17.2432C0.645497 16.5566 0.645497 15.4427 1.26346 14.7561V14.7561Z"
                fill="#2C3532" fillOpacity="0.5"/>
        </svg>
    );
}

export default LeftArrowSvg;