import React from 'react';
import styled from "styled-components";
import logo from "../styles/images/logo.svg";
import PhoneSvg from "../styles/images/PhoneSvg";
import {NavItem} from "./styled/NavItem";
import BurgerSvg from "../styles/images/BurgerSvg";
import MiniLogoSvg from "../styles/images/MiniLogoSvg";

const Logo = styled.img`
  margin-right: auto;
`;

const Contact = styled.div`
  display: flex;
  color: white;
  align-items: center;
  font-family: Arial, sans-serif;
  margin-left: auto;
  font-size: 18px;

  svg {
    flex: 0 0 auto;
    margin-right: 5px;
  }
`;


const MiniLogo = styled(MiniLogoSvg)`
  display: none;
  margin-right: auto;
  flex: 0 0 auto;
`;

const Burger = styled(BurgerSvg)`
  display: none;
  margin-left: 26px;
  flex: 0 0 auto;
`;

const StyledNav = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  padding: 0 145px;
  margin-top: 60px;
  width: 100%;

  ${NavItem} {
    &:first-of-type {
      margin-left: 20px;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  @media (max-width: 1100px) {
    padding: 0 25px;

    ${Contact} {
      margin-left: 10px;
    }

    ${NavItem} {
      display: none;
    }

    ${Burger} {
      display: block;
    }

    ${Logo} {
      display: none;
    }

    ${MiniLogo} {
      display: block;
    }
  }

`;

type NavProps = {
    openMenu: () => void
}

function Nav({openMenu}: NavProps) {
    return (
        <StyledNav>
            <Logo src={logo} alt={"logo"}/>
            <MiniLogo/>
            <NavItem href={"#whycerberus"}>Почему мы</NavItem>
            <NavItem href={"#use"}>Применение</NavItem>
            <NavItem href={"#howwork"}>Принцип работы</NavItem>
            <NavItem href={"#contacts"}>Контакты</NavItem>
            <Contact><PhoneSvg/>+972 584388373</Contact>
            <Burger onClick={openMenu}/>
        </StyledNav>
    );
}

export default Nav;