import React from 'react';
import styled from "styled-components";
// import background from "../styles/images/block-background.png"
// import background_1920 from "../styles/images/block-background_1920.png"
import background_3840 from "../styles/images/block-background_3840.png"
import Button from "../components/Button";

const StyledFirstBlock = styled.div`
  background: url(${background_3840}) no-repeat center;

  background-size: cover;
  width: 100%;
  color: white;
  padding: 145px 145px 70px 145px;


  
  h1 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 33px;
  }

  p {
    padding: 41px 0;
  }


  @media (max-width: 1100px) {
    padding: 145px 25px 70px 25px;
    h1 {
      font-size: 24px;

      br {
        display: none;
      }
    }
  }

`;

function FirstBlock() {
    return (
        <StyledFirstBlock>
            <h1>CERBErus - <br/> надежная защита данных</h1>

            <p>Ваши рабочие данные — это важнейшая составляющая каждого процесса<br/> организации. Поэтому в основе
                стратегии
                нулевого доверия должна лежать <br/> их защита и безопасное использование.</p>

            <Button>Демонстрация продукта</Button>
        </StyledFirstBlock>
    );
}

export default FirstBlock;