import React from 'react';
import styled from "styled-components";
import dislocation1 from "../styles/images/dislocation1.png";
import dislocation2 from "../styles/images/dislocation2.png";
import Button, {StyledButton} from "../components/Button";


const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  h1 {
    color: #057477;
    font-size: 24px;
    font-weight: normal;
    margin-right: 67px;
    white-space: nowrap;
  }

  p {
    color: #2c3532;
    font-size: 16px;
  }
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 2px solid #057477;
`;

const Number = styled.svg`
  position: absolute;
  left: 0;
  height: 120px;

  text {
    font: 60px Audiowide;
    fill: none;
    stroke: #D8B08C;
    stroke-width: .3px;
    stroke-linejoin: round;
  }
`;

const TextBlock = styled.div`
  h2 {
    font-size: 18px;
    color: #2c3532;
    font-weight: normal;
    margin-bottom: 30px;
  }

  p {
    line-height: 24px;
    padding-left: 40px;
    border-left: 2px solid #D8B08C;
  }
`;

const BlockWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${StyledButton} {
    margin-top: 50px;
    padding-left: 55px;
    padding-right: 55px;
  }
`;

const DislocationBlock = styled.div`
  display: flex;

  .secondDis1 {
    display: none;
  }

  img {
    object-fit: contain;
  }

  &:not(:last-child) {
    margin-bottom: 90px;
  }

  *:first-child {
    margin-right: 60px;
  }
`;

const Dislocation = styled.div`
  h1 {
    color: #057477;
    font-size: 24px;
    font-weight: normal;
    margin-top: 70px;
    margin-bottom: 50px;
  }
`;

const Difference = styled.div`
  margin-top: 100px;

  h1 {
    font-size: 18px;
    color: #2c3532;
    margin-bottom: 75px;
  }
`;

const DifferenceBlocks = styled.div`
  display: grid;
  grid-template-columns: 260px 260px auto;
  grid-gap: 15px 30px;
`;

const DifferenceBlock = styled.div`
  position: relative;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.25);
  padding: 70px 30px;
`;


const FirstDiffBlock = styled(DifferenceBlock)`
  ${Number} {
    transform: translateY(-120%);
    height: 240px;
  }

  padding-top: 170px;
  grid-row-start: 1;
  grid-row-end: 3;
`;

const SecondDiffBlock = styled(DifferenceBlock)`
  ${Number} {
    transform: translateY(-120%);
    right: 40px;
    left: auto;
  }

  grid-column-start: 2;
  grid-column-end: 4
`;

const ThirdDiffBlock = styled(DifferenceBlock)`
  ${Number} {
    transform: translateY(-120%) translateX(45%);
    right: 40px;
    left: auto;
  }

  grid-column-start: 4;
  grid-column-end: 5;
`;

const FourthDiffBlock = styled(DifferenceBlock)`
  ${Number} {
    transform: translateY(-100%);
    left: 20px;
  }

  grid-column-start: 2;
  grid-column-end: 2;
`;

const FifthDiffBlock = styled(DifferenceBlock)`
  ${Number} {
    transform: translateY(-100%);
    left: 20px;
  }

  grid-column-start: 3;
  grid-column-end: 5;
`;

const StyledSecondBlock = styled.div`
  background: #E8F0EF;
  padding: 185px 145px 95px 145px;
  width: 100%;
  @media (max-width: 1100px) {
    padding: 80px 25px 95px 25px;

    ${Top} {
      h1 {
        font-size: 20px;
        margin-right: 34px;
      }
    }

    ${Dislocation} {
      ${DislocationBlock} {
        flex-direction: column;

        .firstDis1 {
          display: none;
        }

        .secondDis1 {
          width: 100%;
        }

        ${StyledButton} {
          width: 100%;
        }

        &:not(:last-child) {
          margin-bottom: 70px;
        }
      }
    }

    ${DifferenceBlocks} {
      grid-template-columns: auto auto;
      grid-gap: 36px 20px;
      font-size: 14px;
    }

    ${DifferenceBlock} {
      padding: 40px 10px 40px 25px;
    }

    ${FirstDiffBlock} {

      ${Number} {
        height: 100px;
        left: 40px;
      }

      grid-row-start: 1;
      grid-row-end: 3;

    }

    ${SecondDiffBlock} {

      ${Number} {
        height: 100px;
      }

      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 2;
      grid-column-end: 3;
    }

    ${ThirdDiffBlock} {

      ${Number} {
        height: 130px;
        left: auto;
        right: 0;
        text-align: end;
        transform: translateY(-100%) translateX(0%);
      }

      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    ${FourthDiffBlock} {

      ${Number} {
        height: 80px;
      }

      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    ${FifthDiffBlock} {

      ${Number} {
        height: 90px;
        left: 0;
      }

      grid-row-start: 4;
      grid-row-end: 6;
      grid-column-start: 2;
      grid-column-end: 3;
    }

  }
`;

function SecondBlock() {
    return (
        <StyledSecondBlock>
            <Top><h1 id={"whycerberus"}>Почему cerberus?</h1> <Line/></Top>
            <p>
                Популярные публичные мессенджеры есть у каждого из нас.<br/>
                Мы пользуемся ими как для бизнеса, так и для
                личных дел.<br/>
                В обмен на это они собирают наши данные и не только.
            </p>
            <Difference>
                <h1>5 отличий Сerberusa от привычных всем приложений</h1>
                <DifferenceBlocks>
                    <FirstDiffBlock>
                        <Number viewBox="0 0 50 60">
                            <text y={"60"}>1</text>
                        </Number>
                        Заказчик имеет физический доступ к оборудованию, где хранится его информация
                    </FirstDiffBlock>
                    <SecondDiffBlock>
                        <Number viewBox="0 0 50 60">
                            <text y={"60"}>2</text>
                        </Number>
                        Заказчик может отключить пользователя от доступа к мессенджеру
                    </SecondDiffBlock>
                    <ThirdDiffBlock>
                        <Number viewBox="0 0 50 60">
                            <text y={"60"}>3</text>
                        </Number>
                        Заказчик может провести собственный аудит безопасности мессенджера
                    </ThirdDiffBlock>
                    <FourthDiffBlock>
                        <Number viewBox="0 0 50 60">
                            <text y={"60"}>4</text>
                        </Number>
                        Предоставляет Заказчику контроль над серверным ПО
                    </FourthDiffBlock>
                    <FifthDiffBlock>
                        <Number viewBox="0 0 50 60">
                            <text y={"60"}>5</text>
                        </Number>
                        Заказчик может самостоятельно определять круг пользователей мессенджера
                    </FifthDiffBlock>
                </DifferenceBlocks>
            </Difference>
            <Dislocation>
                <h1>Где размещается Сerberus?</h1>
                <DislocationBlock>
                    <TextBlock>
                        <h2>Размещение на серверах Заказчика</h2>
                        <p>Мы предоставляет вам лицензию на использование ПО Сerberus для организации защищенного обмена
                            корпоративными данными. Вы имеете право установить это ПО на собственных серверах и
                            использовать его для целей своего бизнеса.

                            <br/>
                            <br/>

                            Никто, кроме вас, не имеет законного права распоряжаться вашими данными, передавать их
                            третьим лицам или использовать иным образом без вашего ведома, а технология Сerberus надежно
                            обеспечивает это ваше право. Этим решение Сerberus отличается от публичных мессенджеров.</p>
                    </TextBlock>
                    <img src={dislocation1} alt="dislocation1"/>
                </DislocationBlock>
                <DislocationBlock>
                    <img className={"firstDis1"} src={dislocation2} alt="dislocation2"/>
                    <BlockWrap>
                        <TextBlock>
                            <h2>Размещение на арендованных серверах</h2>
                            <p>Для тех, кто хочет воспользоваться серверами в аренду, мы приготовили защищенные и
                                отказоустойчивые сервера в дата-центрах Израиля, с возможностью мгновенно развернуть на
                                них
                                ПО Сerberus.

                                <br/>
                                <br/>

                                Управление серверами не потребует от вас дополнительных усилий.
                                В любой момент вы сможете одним кликом включить, выключить, перезапустить сервер,
                                перенести
                                ПО и данные на другой выбранный вами сервер.</p>
                        </TextBlock>
                        <img className={"secondDis1"} src={dislocation2} alt="dislocation2"/>
                        <Button>Связаться с нами</Button>
                    </BlockWrap>
                </DislocationBlock>
            </Dislocation>
        </StyledSecondBlock>
    );
}

export default SecondBlock;