import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Nav from "./components/Nav";
import FirstBlock from "./blocks/FirstBlock";
import SecondBlock from "./blocks/SecondBlock";
import ThirdBlock from "./blocks/ThirdBlock";
import FourthBlock from "./blocks/FourthBlock";
import FifthBlock from "./blocks/FifthBlock";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import ArrowSvg from "./styles/images/ArrowSvg";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BackTop = styled.div`
  position: fixed;
  bottom: 2%;
  right: 4%;
  border: 2px solid white;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 40px;
  background: #074c4838;
  z-index: 998;

  svg {
    transform: scale(1.8, 1.5) rotate(180deg);
    flex: 0 0 auto;
  }
`;

function App() {

    const [menuOpened, setMenuOpened] = useState<boolean>(false);

    const [backVisible, setBackVisible] = useState<boolean>(false);

    useEffect(() => {
        console.log(backVisible);

        const onScroll = (e: any) => {
            if (backVisible) {
                if (e.currentTarget.scrollY <= 100)
                    setBackVisible(false)
            } else if (!backVisible) {
                if (e.currentTarget.scrollY > 100)
                    setBackVisible(true)
            }
        }

        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [backVisible])

    return (
        <React.Fragment>
            {menuOpened &&
                <MobileMenu onClose={() => setMenuOpened(false)}/>
            }
            <StyledApp style={{display: menuOpened ? "none" : "flex"}}>
                <Nav openMenu={() => setMenuOpened(true)}/>
                <FirstBlock/>
                <SecondBlock/>
                <ThirdBlock/>
                <FourthBlock/>
                <FifthBlock/>
                <Footer onMobileBurger={() => setMenuOpened(true)}/>
                {backVisible && <BackTop onClick={() => window.scrollTo(0, 0)}>
                    <ArrowSvg/>
                </BackTop>}
            </StyledApp>
        </React.Fragment>
    );
}

export default App;