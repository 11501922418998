import React from 'react';
import styled from "styled-components";
import phone from "../styles/images/phone.png"

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  border-bottom: 2px solid #04898C;

  &:not(:last-child) {
    margin-bottom: 65px;
  }

  h3 {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    color: #2c3532;
  }

  p {
    margin-top: 30px;
    font-size: 16px;
    color: #2c3532;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 70px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 65px;
`;

const StyledThirdBlock = styled.div`
  padding: 100px 145px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 24px;
    color: #057477;
    font-weight: normal;
  }

  h2 {
    font-size: 18px;
    margin-top: 40px;
    font-weight: normal;
    color: #2c3532;
  }

  @media (max-width: 1100px) {
    padding: 100px 25px;
    ${Info} {
      flex-direction: column;

      img {
        width: 100%;
        margin: 50px 0;
      }
    }
  }
`;

function ThirdBlock() {
    return (
        <StyledThirdBlock>
            <h1 id={"use"}>Защищенный мессенджер для закрытых<br/>
                команд на вашем сервере</h1>
            <h2>От 5 человек и больше</h2>
            <Info>
                <InfoBlock>
                    <InfoText>
                        <h3>Зашифрованные сообщения</h3>
                        <p>Обменивайтесь мгновенными, самоуничтожающимися, голосовыми сообщениями</p>
                    </InfoText>
                    <InfoText>
                        <h3>Зашифрованные P2P звонки</h3>
                        <p>Общайтесь с помощью аудио и видеозвонков кристального качества</p>
                    </InfoText>
                    <InfoText>
                        <h3>Защищенная видео-конференция</h3>
                        <p>Проводите защищенные видео-конференции, приглашайте внешних пользователей
                            по простой ссылке</p>
                    </InfoText>
                </InfoBlock>
                <InfoBlock>
                    <img src={phone} alt="phone"/>
                </InfoBlock>
                <InfoBlock>
                    <InfoText>
                        <h3>Зашифрованный обмен файлами</h3>
                        <p>Обменивайтесь файлами всех форматов, включая фото и видео</p>
                    </InfoText>
                    <InfoText>
                        <h3>Зашифрованные групповые чаты</h3>
                        <p>Создавайте и управляйте групповыми чатами</p>
                    </InfoText>
                    <InfoText>
                        <h3>Мультиплатформенное решение</h3>
                        <p>Работает на мобильных устройствах и десктопе,
                            под управлением популярных операционных систем</p>
                    </InfoText>
                </InfoBlock>
            </Info>
        </StyledThirdBlock>
    );
}

export default ThirdBlock;