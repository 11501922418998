import React from 'react';
import styled from "styled-components";
import logo from "../styles/images/logo.svg"
import MobileFooter, {StyledMobileFooter} from "./styled/MobileFooter";

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Phone = styled.div`
  color: #FFFFFF;
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Copyright = styled.div`
  color: #959a98;
  font-size: 12px;
`;

const Links = styled.div`
  margin: 0 65px;
  border-right: 1px solid #D8B08C;
  border-left: 1px solid #D8B08C;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;

  a {
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      opacity: .5;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: stretch;
  background: #2C3532;
  padding: 25px 120px;
  min-height: 142px;
  width: 100%;
`;


const FooterWrap = styled.div`
  width: 100%;
  display: flex;

  ${StyledMobileFooter} {
    display: none;
  }

  @media (max-width: 1100px) {
    ${StyledFooter} {
      display: none;
    }

    ${StyledMobileFooter} {
      display: flex;
      width: 100%;
    }
  }
`;

function Footer({onMobileBurger}: any) {
    return (
        <FooterWrap>
            <StyledFooter>
                <img src={logo} alt="logo"/>
                <Links>
                    <a href="#whycerberus">Почему мы</a>
                    <a href="#use">Применение</a>
                    <a href="#howwork">Принцип работы</a>
                    <a href="#contacts">Контакты</a>
                </Links>
                <Contact>
                    <Phone>
                        +972 584388373
                    </Phone>
                    <Copyright>
                        © Все права защищены
                    </Copyright>
                </Contact>
            </StyledFooter>
            <MobileFooter onBurger={onMobileBurger}/>
        </FooterWrap>
    );
}

export default Footer;