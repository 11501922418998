import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import cross from "../styles/images/cross.png";
import checked from "../styles/images/checked.png";
import LeftArrowSvg from "../styles/images/LeftArrowSvg";

const StyledSliderItem = styled.div`
  transition: all .2s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E8F0EF;
  border-radius: 50px;
  padding: 45px;
  min-height: 100%;


  img {
    margin: 10px 0;
  }

  h3 {
    color: #2c3532;
    font-size: 16px;
    font-family: Raleway, sans-serif;
  }

  .text1 {
    margin-bottom: auto;
  }

  p {
    color: #2c3532;
    line-height: 24px;
  }
`;

const StyledArrow = styled(LeftArrowSvg)`
  position: absolute;
  cursor: pointer;
  user-select: none;
`;

const LeftArrow = styled(StyledArrow)`
  transform: translateX(-150%);
  left: 0;
`;

const RightArrow = styled(StyledArrow)`
  right: 0;
  transform: translateX(150%) rotateY(180deg);
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 1100px) {
    ${LeftArrow} {
      transform: translateX(-100%);
    }

    ${RightArrow} {
      transform: translateX(100%) rotateY(180deg);
    }
  }
`;

const StyledSlider = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  overflow: hidden;
  align-items: stretch;
  width: 100%;
`;


export function SliderItem({parentWidth, style, title, text1, text2}: any) {

    return (
        <StyledSliderItem
            style={{
                ...style,
                margin: "0 10px"
            }}>
            <h3>{title}</h3>
            <img src={cross} alt=""/>
            <p className={"text1"}>{text1}</p>
            <img src={checked} alt=""/>
            <p>{text2}</p>
        </StyledSliderItem>
    )
}

function Slider({slides, count}: any) {
    const ref = useRef<any>(null);
    const [width, setWidth] = useState<number>(0);

    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        setWidth(window.innerWidth - ((count === 1 ? 25 : 145) * 2) - (count === 1 ? 0 : 20))
    }, [count])

    useEffect(() => {
        window.onresize = (e: any) => {
            setWidth(e.currentTarget.innerWidth - ((count === 1 ? 25 : 145) * 2) - (count === 1 ? 0 : 20))
        };

        return () => {
            window.onresize = null;
        }
    }, [count])

    const moveSlided = (number: number) => {
        if (offset + number < 0 || offset + number >= slides.length - 1) {
            return
        }
        setOffset(oldOffset => oldOffset + number)
    }

    return (
        <StyledWrap>
            <LeftArrow onClick={() => moveSlided(-1)}>{"<"}</LeftArrow>
            <StyledSlider style={{maxWidth: width}} ref={ref}>
                {slides.map((slide: any, index: number) => (
                    <SliderItem key={index}
                                title={slide.title}
                                text1={slide.text1}
                                text2={slide.text2}
                                parentWidth={width}
                                style={{
                                    left: -offset * ((width) / count),
                                    minWidth: ((width) / count) - 20,
                                    maxWidth: ((width) / count) - 20
                                }}
                    />
                ))}
            </StyledSlider>
            <RightArrow onClick={() => moveSlided(1)}>{">"}</RightArrow>
        </StyledWrap>
    );
}

export default Slider;